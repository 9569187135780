import React from 'react';
import {
    Flex,
    Text,
    HStack,
} from '@chakra-ui/react'
import BaseDeleteModal from '../../components/basePage/baseDeleteModal'
import EmailPopover from './emailPopover';

export default function MemberListItem({item, entityName, updateData, bic}){
    return (
        <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="flex-start" justifyContent='space-between' key={item.id} w='full' maxW='400px'>
            <Text mr='4' >{ item.name }</Text>
            {!bic && <HStack>
                <EmailPopover item={item}/>
                <BaseDeleteModal id={item.id} entityName={entityName} callback={updateData}/>
            </HStack>}
        </Flex>
    );
};
