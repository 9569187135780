import React, { useState, useEffect, useContext } from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    Input,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react'
import SelectSearch from 'react-select-search';
import { FoodContext } from '../../providers/foodProvider';
import 'react-select-search/style.css';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function MealFoodForm({title, data, setData, foreignKey, isEdit}){
    const [foods, setFoods] = useState();
    const [foodId, setFoodId] = useState('');
    const { getFoods } = useContext(FoodContext);
    const { notify } = useContext(ErrorNotifierContext);

    useEffect(() => {
        getFoods()
            .then((foods) => {
                setFoods(foods)
            })
            .catch((error) => {
                notify(error, 'Sikertelen lekérdezése az ételeknek');
            });
    //eslint-disable-next-line
    }, []);

    const chooseFood = (foodId) => {
        if (!data) {
            data = { mealId: foreignKey };
        }
        data.foodId = foodId;
        setFoodId(foodId);
        setData(data);
    };

    const setQuantity = (quantity) => {
        if (!data) {
            data = { mealId: foreignKey };
        }
        data.quantity = quantity;
        setData(data);
    };

    return (
        <VStack w='full'>
            <FormControl>
                {!isEdit && <>
                    <FormLabel>{title}</FormLabel>
                    <SelectSearch
                        className="select-search"
                        options={(!!foods && foods.length > 0) ? foods.map(item => ({
                            value: item.id,
                            name: item.name,
                        })) : []}
                        search
                        placeholder="Válassz ételt"
                        value={foodId}
                        onChange={value => chooseFood(value)}/>
                    </>}
                <FormLabel mt='4'>Mennyiség</FormLabel>
                <InputGroup>
                    <Input type="number" step='0.01' textAlign='end' defaultValue={!!data ? data.quantity : ''} onChange={(event) => setQuantity(event.target.value)} mb='4' autoComplete='off'/>
                    <InputRightElement>db</InputRightElement>
                </InputGroup>
            </FormControl>
        </VStack>
    );
};
