import React from 'react';
import {
    Flex,
    Text,
    HStack,
} from '@chakra-ui/react'
import BaseDeleteModal from '../../components/basePage/baseDeleteModal'

export default function CompositeItemListItem({item, entityName, formTitle, updateData, form, foreignKey, bic}){
    return (
        <Flex bg='#548235' color='whitesmoke' p={4} gap="2" borderRadius={10} flexDir="row" alignItems="flex-start" justifyContent='space-between' key={item.id} w='full' maxW='400px'>
            <HStack>
                <Text mr='4' >{ item.productName }</Text>
                
            </HStack>
            {!bic && <HStack>
                <Text mr='4' >{ item.quantityPerPortion } db / adag</Text>
                <BaseDeleteModal id={item.id} entityName={entityName} callback={updateData}/>
            </HStack>}
        </Flex>
    );
};
