import React, { useState, useEffect, useContext } from 'react';
import {
    Select,
    Text
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function MemberSelector({onChange}){
    const [data, setData] = useState();

    const { notify } = useContext(ErrorNotifierContext);

    useEffect(() => {
        if (!!localStorage.getItem('householdId')) {
            axios.get(apiConfig.webApi + 'members?householdId=' + localStorage.getItem('householdId'))
            .then((response) => {
                if (!!response.data.list && response.data.list.length > 0) {
                    onChange(response.data.list[0].id);
                }
                setData(response.data.list);
            })
            .catch((error) => {
                notify(error, 'Sikertelen lekérdezése a tagoknak');
            });
        }
    //eslint-disable-next-line
    }, []);

    if (!data || data.length === 0) {
        return <Text>Adj hozzá tagot a háztartásodhoz!</Text>;
    }

    return (
        <Select maxW='200px' onChange={e => onChange(e.target.value)}>
            {!!data && data.length > 0 && data.map(item => <option value={item.id} key={item.id}>{ item.name }</option>)}
        </Select>
    );
};
