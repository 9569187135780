import React from 'react';
import {
	VStack,
	Center,
	Text
} from '@chakra-ui/react'

export default function NoHouseholdPage() {
    return (
        <Center mt='20'>
            <VStack my="10" mx="5" fontSize='xl' width="4xl"  spacing={4}>
                <Text>
                    Hozd létre az első háztartásod a funkció használatához!
                </Text>
            </VStack>
        </Center>
    );
};