import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
    ModalHeader,
    ModalCloseButton,
    IconButton,
    VStack,
    HStack,
    Button,
    Text,
    Flex
  } from '@chakra-ui/react'
import { BarcodeScanner } from 'react-barcode-scanner';
import { FaBarcode } from "react-icons/fa";
import { CheckIcon, RepeatIcon } from '@chakra-ui/icons'

export default function BarcodeScannerModal({callback}) {
    const [ barcode, setBarcode ] = useState('XXXXXXXXX');
    const [ captured, setCaptured ] = useState(false);

    const capture = (data) => {
        if (!captured) {
            setBarcode(data.rawValue);
            setCaptured(true);
        }
    }

    const clear = () => {
        setBarcode('XXXXXXXXX');
        setCaptured(false);
    }

    const ok = () => {
        if (captured) {
            callback(barcode);
        }
        else {
            callback('');
        }
        onClose();
    }

    const { isOpen, onOpen, onClose } = useDisclosure()
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    
    return (
      <>
        <IconButton icon={<FaBarcode color='black'/>} variant='outline' onClick={onOpen}/>
  
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}>
            <ModalOverlay />
            <ModalContent width="90%" p='2' maxW='350px'>
                <ModalHeader></ModalHeader>
                <ModalCloseButton/>
                <VStack>
                    {!captured ? <BarcodeScanner onCapture={data => capture(data)} options={{formats: ['ean_13', 'ean_8', 'upc_a', 'upc_e'], delay: 200 }}/> : <CheckIcon color='green' fontSize='xxx-large' my='100px'/>}
                    <Flex justifyContent='space-between' flexDir='row' w='95%'>
                        <Text alignSelf='center'>{ barcode }</Text>
                        <HStack>
                            <Button leftIcon={<RepeatIcon />} isDisabled={!captured} onClick={clear}>Újra</Button>
                            <IconButton icon={<CheckIcon/>} colorScheme='green' onClick={ok}/>
                        </HStack>
                    </Flex>
                </VStack>
            </ModalContent>
        </Modal>
      </>
    )
}
