import React, { useContext } from 'react';
import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    IconButton,
    useToast
} from '@chakra-ui/react'
import { MdRemoveCircle } from "react-icons/md";
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function BaseDeleteModal({id, entityName, callback}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    const toast = useToast();
    const { notify } = useContext(ErrorNotifierContext);

    const deleteFunction = () => {
        axios.delete(apiConfig.webApi + entityName + '/' + id)
            .then(() => {
                onClose();
                callback(id);
                toast({ title: 'Sikeres törlés', status: 'success', duration: 2000, isClosable: true});
            })
            .catch((error) => {
                notify(error, 'Sikertelen törlés');
            });
    };
  
    return (
      <>
        <IconButton icon={<MdRemoveCircle/>} onClick={onOpen} boxSize='8' size='xl' color='whitesmoke' bg='transparent'/>
  
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent width="90%">
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>Törlés megerősítése</AlertDialogHeader>
  
                        <AlertDialogBody>
                            Biztosan törölni szeretnéd? Ezt a műveletet nem tudod visszavonni.
                        </AlertDialogBody>
  
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Mégse
                        </Button>
                        <Button colorScheme='red' onClick={() => deleteFunction()} ml={3}>
                            Törlés
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
}
