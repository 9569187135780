import React, { useState } from 'react';
import {
	FormControl,
    FormLabel,
    Input,
    Text,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useDisclosure,
    ModalBody,
    ModalCloseButton,
    Center,
    VStack
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig'
import { CheckCircleIcon } from '@chakra-ui/icons';
const { sha512 } = require('js-sha512');

export default function ForgottenPassword() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resetCode, setResetCode] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [resetSuccessful, setResetSuccessful] = useState(false);

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const sendData = (event) => {
        event.preventDefault();
        if (!resetCode) {
            axios.post(apiConfig.baseUrl + 'forgotPassword', { email: email }, { timeout: 5000 })
                .then((response) => {
                    if (response.status === 200) {
                        toast({ title: 'Elküldtük az visszaállító kódot az email címedre', status: 'success', duration: 2000, isClosable: true});
                        setEmailSent(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast({ title: 'Nem sikerült elküldeni az emailt', status: 'error', duration: 2000, isClosable: true});
                });
        }
        else {
            axios.post(apiConfig.baseUrl + 'resetPassword', { email: email, resetCode: resetCode, newPassword: sha512(password) }, { timeout: 5000 })
                .then((response) => {
                    if (response.status === 200) {
                        toast({ title: 'Sikeresen visszaállítottuk a profilodat', status: 'success', duration: 2000, isClosable: true});
                        setResetSuccessful(true);
                        onClose();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast({ title: 'Nem sikerült a profil helyreállítása', status: 'error', duration: 2000, isClosable: true});
                });
        }
        
    };

    return (
        <>
            <Text fontSize='12' as='u' onClick={onOpen} className='cursor'>Elfelejtettem a jelszavam</Text>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW='300px'>
                    <ModalHeader>Elfelejtettem a jelszavam</ModalHeader>
                    <ModalCloseButton />
                    {!resetSuccessful ?
                    <form onSubmit={sendData}>
                        <ModalBody mb='4'>
                            {!emailSent &&
                                <FormControl mt='5' mb='1'>
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email" onChange={(event) => setEmail(event.target.value)}  autoComplete='off' isRequired={true}/>
                                </FormControl>
                            }

                            {!!emailSent && <>
                                <FormControl mt='5'>
                                    <FormLabel>Emailben kapott kód</FormLabel>
                                    <Input type="text" onChange={(event) => setResetCode(event.target.value)}  autoComplete='off'/>
                                </FormControl>

                                <FormControl mt='5'>
                                    <FormLabel>Új jelszó</FormLabel>
                                    <Input type="password" onChange={(event) => setPassword(event.target.value)}  autoComplete='off'/>
                                </FormControl>
                            </>}
                            

                            <FormControl mt='5'>
                                <Center>
                                    <Button type="submit">{!emailSent ? 'Helyreállító email küldése' : 'Profil helyreállítása'}</Button>
                                </Center>
                            </FormControl>
                        </ModalBody>
                    </form> : 
                    <Center>
                        <VStack>
                            <CheckCircleIcon fontSize='lg' color='#548235' mb='4'/>
                            <Text mb='4'>Sikeresen visszaállítottuk a profilodat</Text>
                        </VStack>
                    </Center>}
                </ModalContent>
            </Modal>
        </>
    );
};
