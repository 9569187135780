import React, { useContext } from 'react';
import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    IconButton,
    useToast,
    Tooltip
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { FaStop } from "react-icons/fa";
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function ConfirmComplete({id, callback}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    const { notify } = useContext(ErrorNotifierContext);

    const complete = () => {
        axios.post(apiConfig.webApi + 'shoppings/complete/' + id, {})
            .then(() => {
                onClose();
                callback();
                toast({ title: 'Sikeres lezárás', status: 'success', duration: 2000, isClosable: true});
            })
            .catch((error) => {
                notify(error, 'Sikertelen lezárás');
            });
    }

    const toast = useToast();
  
    return (
      <>
        <Tooltip label="Lezárás">
            <IconButton icon={<FaStop/>} onClick={onOpen} boxSize='8' size='xl' color='whitesmoke' bg='transparent'/>
        </Tooltip>
  
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent width="90%">
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>Lezárás megerősítése</AlertDialogHeader>
  
                        <AlertDialogBody>
                            Biztosan le szeretnéd zárni a bevásárlást? Csak akkor zárd le, ha tényleg befejezted a vásárlást. 
                        </AlertDialogBody>
  
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Mégse
                        </Button>
                        <Button colorScheme='green' onClick={complete} ml={3}>
                            Lezárás
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
}
