import React, { useState, useEffect, useContext } from 'react';
import {
    VStack,
    Center,
    Heading,
    Flex,
    Box,
} from '@chakra-ui/react'
import axios from 'axios';
import BaseListPage from '../../components/basePage/baseListPage';
import MealTypeForm from './mealTypeForm';
import MealTypeListItem from './mealTypeListItem';
import apiConfig from '../../config/apiconfig';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

const MealTypesListPage = ({ householdId }) => {
    const [data, setData] = useState();

    const { notify } = useContext(ErrorNotifierContext);

    const doFetch = () => {
        if (!!householdId)
        axios.get(apiConfig.webApi + 'members?householdId=' + householdId)
            .then((response) => {
                setData(response.data.list);
            })
            .catch((error) => {
                notify(error, 'Sikertelen lekérdezése a tagoknak');
            });
    };

    useEffect(() => {
        doFetch();
    // eslint-disable-next-line
    }, []);

    return(
            <Center mt='20'>
                {data && <VStack my="5" mx="5" fontSize='xl'>
                    <Flex justifyContent='space-between' w='full' maxW='400px' my='6'>
                        <Heading>Étkezések</Heading>
                    </Flex>
                    {data.map(item => <Box bg='#548235' borderRadius='10' maxW='400px' key={item.id}>
                        <BaseListPage
                            entityName='mealtypes'
                            title={item.name}
                            formTitle='Étkezés'
                            listItem={MealTypeListItem}
                            form={MealTypeForm}
                            householdId={householdId}
                            bg='#ed7d31'
                            mt='0'
                            foreignKeyName='memberId'
                            foreignKey={item.id}
                            titleColor='whitesmoke'
                            sortStrategy={(a, b) => (Date.parse('01/01/2024 ' + a.time) > Date.parse('01/01/2024 ' + b.time)) ? 1 : ((Date.parse('01/01/2024 ' + a.time) < Date.parse('01/01/2024 ' + b.time)) ? -1 : 0)}/>
                    </Box>)}
                </VStack>}
            </Center>
        );
};

export default MealTypesListPage;
