import React, { useState, useContext } from 'react';
import {
    Select,
    Link
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { useNavigate } from 'react-router-dom';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function HouseholdSelector({color}){
    const [data, setData] = useState();

    const navigate = useNavigate();
    const { notify } = useContext(ErrorNotifierContext);

    const doFetch = () => {
        if (!data) {
            axios.get(apiConfig.webApi + 'households')
                .then((response) => {
                    setData(response.data.list);
                    const householdId = localStorage.getItem('householdId');
                    if (!householdId && !!response.data.list  && response.data.list.length > 0){
                        localStorage.setItem('householdId', response.data.list[0].id);
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezése a háztartásoknak');
                });
        }
    };

    const handleSelect = (e) => {
        localStorage.setItem('householdId', e.target.value);
        window.location.reload();
    }

    doFetch();

    if (!data || data.length === 0) {
        return <Link onClick={() => navigate("/households")} _hover={false} alignSelf={"center"} fontWeight={'bold'}>Háztartásaim kezelése</Link>
    }

    return (
        <Select maxW='200px' onChange={handleSelect} value={localStorage.getItem('householdId')}>
            {!!data && data.length > 0 && data.map(item => <option value={item.id} key={item.id} style={{color: color}}>{ item.name }</option>)}
        </Select>
    );
};
