import React from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    Input
} from '@chakra-ui/react'

export default function ShoppingForm({title, data, setData}){
    const setDate = (date) => {
        if (!data) {
            data = {};
        }
        if (!data.householdId) {
            data.householdId = localStorage.getItem('householdId');
        }
        date = date + '+00:00';
        data.dateTime = date;
        setData(data);
    };

    return (
        <VStack w='full'>
            <FormControl>
                <FormLabel>{title} időpontja</FormLabel>
                <Input type="datetime-local" defaultValue={!!data && !!data.dateTime ? new Date(data.dateTime).toISOString().slice(0, 16) : ''} onChange={(event) => setDate(event.target.value)} />
            </FormControl>
        </VStack>
    );
};
