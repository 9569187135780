import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    Input,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react'
import ProductSelector from '../../components/products/productSelector';

export default function StockItemForm({title, data, setData, foreignKey, isEdit}){
    const [prodId, setProdId] = useState('');

    const setProductId = (productId) => {
        if (!data) {
            data = { householdId: foreignKey };
        }
        data.productId = productId;
        setProdId(productId);
        setData(data);
    };

    const setQuantity = (quantity) => {
        if (!data) {
            data = { householdId: foreignKey };
        }
        data.quantity = quantity;
        setData(data);
    };



    return (
        <VStack w='full'>
            <FormControl>
                <ProductSelector prodId={prodId} setProductId={setProductId} visible={!isEdit} title={title}/>
                <FormLabel mt='4'>Mennyiség</FormLabel>
                <InputGroup>
                    <Input type="number" step='1' textAlign='end' defaultValue={!!data ? data.quantity : ''} onChange={(event) => setQuantity(event.target.value)} mb='4' autoComplete='off'/>
                    <InputRightElement>db</InputRightElement>
                </InputGroup>
            </FormControl>
        </VStack>
    );
};
