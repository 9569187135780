import React from 'react';
import BaseListPage from '../../components/basePage/baseListPage';
import ShoppingItemForm from './shoppingItemForm';
import ShoppingItemListItem from './shoppingItemListItem';
import { useLocation } from 'react-router-dom';

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ShoppingList() {
    let query = useQuery();
	const shoppingId = query.get("id");
    const bought = query.get("bought");
    
    return (
        <BaseListPage
            entityName='shoppingitems'
            title={bought ? 'Megvásárolt termékek' : 'Bevásárló lista'}
            formTitle='Termék'
            listItem={ShoppingItemListItem}
            form={ShoppingItemForm}
            foreignKeyName='shoppingId'
            foreignKey={shoppingId}
            bg='#ed7d31'
            mildReload={true}
            additionalUrl={bought ? '&bought=true' : undefined}
            buttonsInvisible={bought}
            sortStrategy={(a, b) => (a.productName > b.productName) ? 1 : ((a.productName < b.productName) ? -1 : 0)}/>
    )
}
