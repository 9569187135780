import React, { useState } from 'react';
import {
	VStack,
	Center,
	FormControl,
    FormLabel,
    Input,
    Text,
    Button,
    useToast,
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig'
import Register from './register';
import ForgottenPassword from './forgottenPassword';
const { sha512 } = require('js-sha512');

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const toast = useToast();

    const handleLogin = (event) => {
        event.preventDefault();
        axios.post(apiConfig.baseUrl + 'login', { email: email, password: sha512(password)}, { timeout: 5000 })
            .then((response) => {
                if (response.status === 200) {
                    setEmail('');
                    setPassword('');
                    console.log(response);
                    localStorage.setItem("accessToken", response.data.accessToken);
                    localStorage.setItem("refreshToken", response.data.refreshToken);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
                    toast({ title: 'Sikeres bejelentkezés', status: 'success', duration: 2000, isClosable: true});
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 401) {
                    if (error.response?.data?.detail === 'NotAllowed') {
                        toast({ title: 'Igazold vissza az email címed a belépéshez', status: 'error', duration: 2000, isClosable: true});
                    } else {
                        toast({ title: 'Érvénytelen belépési adatok', status: 'error', duration: 2000, isClosable: true});
                    }
                } else {
                    toast({ title: 'Sikertelen kapcsolódás a szerverhez', status: 'error', duration: 2000, isClosable: true});
                }
            });
    };

    return (
        <Center backgroundImage='bgcounter.png' backgroundRepeat='no-repeat' backgroundAttachment='fixed' backgroundSize='cover' className='login'>
            <VStack my="40" mx="5" fontSize='xl' spacing={4} bg='#548235' borderRadius='15' padding='35' color='white'>
                <Text>Bejelentkezés</Text>
                <form onSubmit={handleLogin}>
                    <FormControl mt='5'>
                        <FormLabel>Email</FormLabel>
                        <Input type="email" onChange={(event) => setEmail(event.target.value)}  autoComplete='off' isRequired={true}/>
                    </FormControl>
                    <FormControl mt='5'>
                        <FormLabel>Jelszó</FormLabel>
                        <Input type="password" onChange={(event) => setPassword(event.target.value)}  autoComplete='off' isRequired={true}/>
                    </FormControl>

                    <FormControl mt='5'>
                        <Button type="submit">Bejelentkezés</Button>
                    </FormControl>
                </form>
                <VStack mt='2' alignItems='start' w='full'>
                    <Register/>
                    <ForgottenPassword />
                </VStack>
            </VStack>
        </Center>
    );
};
