import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
    ModalHeader,
    ModalCloseButton,
    Flex,
    VStack,
    Text,
    HStack
  } from '@chakra-ui/react'
import BaseListPage from '../../components/basePage/baseListPage';
import BaseDeleteModal from '../../components/basePage/baseDeleteModal';
import MealFoodListItem from './mealFoodListItem';
import MealFoodForm from './mealFoodForm';

export default function MealFoodModal({item, callback, canDelete}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    
    return (
      <>
        <Flex bg='#548235' w='100px' color='whitesmoke' pl='10px' py='4px' borderRadius={5} flexDir="row" alignItems="flex-start" justifyContent='space-between'>
            <VStack alignItems='start'>
                <Text onClick={onOpen} className='cursor'>{item.mealTypeName}</Text>
                <Text maxW='55px' onClick={onOpen} className='cursor'>{item.foodNames}</Text>
            </VStack>
            <HStack>
                {canDelete && <BaseDeleteModal id={item.id} entityName='meals' callback={callback}/>}
            </HStack>
        </Flex>
  
        <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={() => {callback(); onClose();}}>
            <ModalOverlay />
            <ModalContent width="90%">
                <ModalHeader></ModalHeader>
                <ModalCloseButton/>
                <BaseListPage entityName='foodmealitems' title='Ételek' formTitle='Étel' listItem={MealFoodListItem} form={MealFoodForm} mt='0' foreignKeyName='mealId' foreignKey={item.id} bg='#548235' mildReload={true} sortStrategy={(a, b) => (a.foodName > b.foodName) ? 1 : ((a.foodName < b.foodName) ? -1 : 0)}/>
            </ModalContent>
        </Modal>
      </>
    )
}
