import React, { createContext, useState } from 'react';
import axios from 'axios';
import apiConfig from '../config/apiconfig';

export const FoodContext = createContext();

export const FoodProvider = ({ children }) => {
    const [products, setProducts] = useState();
    const [foods, setFoods] = useState();

    const getProducts = () => {
        return new Promise((resolve, reject) => {
            if (products) {
                resolve(products);
            } else {
                axios.get(apiConfig.webApi + 'products?householdId=' + localStorage.getItem('householdId'))
                    .then((response) => {
                        setProducts(response.data.list);
                        resolve(response.data.list);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    };

    const getFoods = () => {
        return new Promise((resolve, reject) => {
            if (foods) {
                resolve(foods);
            } else {
                axios.get(apiConfig.webApi + 'foods?householdId=' + localStorage.getItem('householdId'))
                    .then((response) => {
                        setFoods(response.data);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    };

    const value = {
        getProducts,
        getFoods
    };

    return (
        <FoodContext.Provider value={value}>
            {children}
        </FoodContext.Provider>
    );
};
