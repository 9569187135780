import React from 'react';
import {
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
} from '@chakra-ui/react'

export default function MindmegetteRecipeForm({ customOnClose, title, setTitle, recipe, setRecipe, onSubmit }) {
    return (
        <>
            <form onSubmit={onSubmit}>
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Étel neve</FormLabel>
                        <Input type="text" value={title} onChange={(event) => setTitle(event.target.value)} autoComplete='off' />
                    </FormControl>

                    <FormControl mt='4'>
                        <FormLabel>Elkészítés</FormLabel>
                        <Textarea minH="300px" value={recipe} onChange={(event) => setRecipe(event.target.value)} autoComplete='off' />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={customOnClose}>Mégse</Button>
                    <Button type="submit" bg='#548235' color='whitesmoke' ml={3}>Létrehozás</Button>
                </ModalFooter>
            </form>
        </>
    )
}
