const baseUrl = "https://mealplannerapi.andai.hu/";

const apiConfig = {
    frontend: "https://mealplanner.andai.hu",
    baseUrl: baseUrl,
    webApi: baseUrl + "api/",
    imageRoute: baseUrl + "images/"
};

export default apiConfig;
