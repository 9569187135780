import React from 'react';
import BaseListPage from '../../components/basePage/baseListPage';
import StockItemForm from './stockItemForm';
import StockItemListItem from './stockItemListItem';

export default function StockItemsPage({householdId}) {
    return (<>
        <BaseListPage
                                entityName='stockitems'
                                title='Készlet'
                                formTitle='Készletelem'
                                listItem={StockItemListItem}
                                form={StockItemForm}
                                foreignKeyName='householdId'
                                foreignKey={householdId}
                                bg='#ed7d31'
                                mildReload={true}
                                sortStrategy={(a, b) => (a.productName > b.productName) ? 1 : ((a.productName < b.productName) ? -1 : 0)}/>
    </>)
}
