import React, { useEffect, useState, useContext } from 'react';
import {
    InputGroup,
    Input,
    InputRightElement,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    useToast,
    IconButton,
} from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { AtSignIcon } from '@chakra-ui/icons'
import SendInvitationModal from './sendInvitationModal';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function EmailPopover({item}){
    const [email, setEmail] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [changed, setChanged] = useState(false);

    const toast = useToast();
    const { notify } = useContext(ErrorNotifierContext);

    const inputChanged = (event) => {
        if (!changed) {
            setChanged(true);
        }
        setEmail(event.target.value);
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const saveUser = () => {
        if (changed) {
            if (validateEmail(email)) {
                axios.post(apiConfig.webApi + 'members/adduser', { memberId: item.id, userEmail: email })
                    .then(() => {
                        setChanged(false);
                        toast({ title: 'Sikeres összerendelés', status: 'success', duration: 2000, isClosable: true})
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            setModalIsOpen(true);
                        }
                        else {
                            notify(error, 'Sikertelen összerendelés');
                        }
                    });
            }
            else {
                toast({ title: 'Kérlek email címet adj meg!', status: 'error', duration: 2000, isClosable: true})
            }
        }
        else {
            axios.post(apiConfig.webApi + 'members/removeuser', { memberId: item.id, userEmail: '' })
            .then(() => {
                setEmail('');
                setChanged(true);
                toast({ title: 'Sikeres törlés', status: 'success', duration: 2000, isClosable: true})
            })
            .catch((error) => {
                notify(error, 'Sikertelen törlés');
            });
        }
    };

    useEffect(() => {
        if (!email) {
            axios.get(apiConfig.webApi + 'members/user/' + item.id)
                .then(response => {
                    setEmail(response.data);
                    if (!response.data) {
                        setChanged(true);
                    }
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezés');
                });
        }
    //eslint-disable-next-line
    }, [email]);

    return (
        <Popover>
            <PopoverTrigger>
                <IconButton icon={<AtSignIcon/>} boxSize='8' size='xl' color='whitesmoke' bg='transparent' />
            </PopoverTrigger>
            <PopoverContent w='320px' m='2'>
                <PopoverArrow />
                <PopoverCloseButton color='black'/>
                <PopoverHeader color='black' fontSize='12'>Felhasználó hozzárendelése</PopoverHeader>
                <PopoverBody>
                    <SendInvitationModal email={email} isOpen={modalIsOpen} closeCallback={setModalIsOpen} memberId={item.id} deleteSetCallback={setChanged}/>
                    <InputGroup size='md' w='300px'>
                        <Input pr='5rem' type='text' placeholder='A felhasználó email címe' value={email} onChange={(event) => inputChanged(event)} color='black'/>
                        <InputRightElement width='4.5rem' mr='1'>
                            <Button h='1.75rem' size='sm' onClick={saveUser}>{changed ? 'Mentés' : 'Törlés'}</Button>
                        </InputRightElement>
                    </InputGroup>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
