import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
    Button,
    ModalHeader,
    ModalCloseButton,
  } from '@chakra-ui/react'
import MemberListItem from './memberListItem';
import MemberForm from './memberForm';
import BaseListPage from '../../components/basePage/baseListPage';
import { MdPerson } from "react-icons/md";

export default function MembersModal({membersCount, householdId}) {
    const { isOpen, onOpen } = useDisclosure()
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const customOnClose = () => {
      window.location.reload();
    }
    
    return (
      <>
        <Button leftIcon={<MdPerson color='whitesmoke'/>} variant='outline' onClick={onOpen} color='whitesmoke'>{membersCount}</Button>
  
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={customOnClose}
        >
          <ModalOverlay />
          <ModalContent width="90%">
            <ModalHeader></ModalHeader>
            <ModalCloseButton/>
            <BaseListPage entityName='members' title='Tagok' formTitle='Új tag' listItem={MemberListItem} form={MemberForm} mt='0' foreignKeyName='householdId' foreignKey={householdId} bg='#548235'/>
          </ModalContent>
        </Modal>
      </>
    )
}
