import React, { useState, useEffect, useContext } from 'react';
import {
    HStack,
    FormLabel,
} from '@chakra-ui/react'
import 'react-select-search/style.css'
import { FoodContext } from '../../providers/foodProvider';
import SelectSearch from 'react-select-search';
import BarcodeScannerModal from './barcodeScannerModal';
import apiConfig from '../../config/apiconfig';
import axios from 'axios';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function ProductSelector({prodId, setProductId, visible, title}) {
    const [products, setProducts] = useState([]);
    const { getProducts } = useContext(FoodContext);

    const { notify } = useContext(ErrorNotifierContext);

    useEffect(() => {
        getProducts()
            .then((products) => {
                setProducts(products)
            })
            .catch((error) => {
                notify(error, 'Sikertelen lekérdezése a termékeknek');
            });
    //eslint-disable-next-line
    }, []);

    const barcodeCallback = (barcode) => {
        axios.get(apiConfig.webApi + 'products/barcode/' + barcode)
            .then(result => setProductId(result.data))
            .catch(error => {
                notify(error, 'Sikertelen lekérdezése a terméknek');
            });
    };

    return (<>
        {visible && products.length > 0 && <>
            <FormLabel>{title}</FormLabel>
            <HStack>
                <SelectSearch
                    className="select-search"
                    options={(!!products && products.length > 0) ? products.map(item => ({
                        value: item.id,
                        name: item.name,
                    })) : []}
                    search
                    placeholder="Válassz terméket"
                    value={prodId}
                    onChange={value => setProductId(value)}/>
                <BarcodeScannerModal callback={barcodeCallback}/>
            </HStack> 
        </>}
    </>
    )
}
