import React, { useState, useContext } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
    Button,
    Text,
    Input,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast
  } from '@chakra-ui/react'
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function SendInvitationModal({email, isOpen, closeCallback, memberId, deleteSetCallback}) {
    const [name, setName] = useState();

    const { onClose } = useDisclosure();

    const toast = useToast();
    const { notify } = useContext(ErrorNotifierContext);
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const customOnClose = () => {
        closeCallback(false);
        onClose();
    }

    const sendInvitation = () => {
        if (!!name){
            axios.post(apiConfig.webApi + 'members/sendinvitation', { memberId: memberId, userEmail: email, userName: name, url: apiConfig.frontend })
                .then(() => {
                    toast({ title: 'Sikeresen elküldtük a meghívót', status: 'success', duration: 2000, isClosable: true})
                    deleteSetCallback(false);
                    customOnClose();
                })
                .catch((error) => {
                    notify(error, 'Sikertelen meghívó küldés');
                });
        }
    };
    
    return (
      <>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={customOnClose}
        >
          <ModalOverlay />
          <ModalContent width="90%">
            <ModalHeader></ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <Text mb='6'>Nem létezik felhasználó a megadott email címmel a rendszerünkben. Ha szeretnél neki meghívót küldeni, add meg a nevét is!</Text>
                <Input type='text' placeholder='Az értesítendő felhasználó neve' value={name} onChange={(event) => setName(event.target.value)} color='black'/>
            </ModalBody>
            <ModalFooter>
                <Button onClick={customOnClose}>Mégse</Button>
                <Button bg='#548235' color='whitesmoke' ml={3} onClick={sendInvitation}>Küldés</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}
