import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    Input,
    HStack
} from '@chakra-ui/react'
import DragDrop from '../../components/products/dragDrop';
import BarcodeScannerModal from '../../components/products/barcodeScannerModal';

export default function ProductForm({title, data, setData, setImage}){
    const [ barcode, setBarcode ] = useState(!!data?.barcode ? data?.barcode : 'XXXXXXXXX');

    const setName = (name) => {
        if (!data) {
            data = {};
        }
        if (!data.HouseholdId) {
            data.HouseholdId = localStorage.getItem('householdId');
            data.householdId = localStorage.getItem('householdId');
        }
        data.ProductName = name;
        data.name = name;
        setData(data);
    };

    const setImageWrapper = (image) => {
        if (!data) {
            data = {};
        }
        if (!data.HouseholdId) {
            data.HouseholdId = localStorage.getItem('householdId');
            data.householdId = localStorage.getItem('householdId');
        }
        if (!data.ProductName && !!data.name){
            data.ProductName = data.name;
        }
        setImage(image);
    };

    const setBarcodeWrapper = (barcode) => {
        if (!data) {
            data = {};
        }
        if (!data.HouseholdId) {
            data.HouseholdId = localStorage.getItem('householdId');
            data.householdId = localStorage.getItem('householdId');
        }
        if (!data.ProductName && !!data.name){
            data.ProductName = data.name;
        }
        data.barcode = barcode;
        setBarcode(!!barcode ? barcode : 'XXXXXXXXX');
        setData(data);
    };

    return (
        <VStack w='full'>
            <FormControl>
                <FormLabel>{title} neve</FormLabel>
                <Input type="text" defaultValue={!!data ? data.name : ''} onChange={(event) => setName(event.target.value)} mb='4' autoComplete='off'/>
                <FormLabel>Kép a termékről</FormLabel>
                <DragDrop onFileChanged={setImageWrapper}/>
                <FormLabel mt='4'>Vonalkód</FormLabel>
                <HStack>
                    <Input disabled={true} value={!!barcode ? barcode : ''} />
                    <BarcodeScannerModal callback={setBarcodeWrapper}/>
                </HStack>
            </FormControl>
        </VStack>
    );
};
