import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    IconButton,
    useDisclosure,
    Stack,
    Link,
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { useNavigate } from "react-router-dom";
import HouseholdSelector from './householdSelector';
import { IoIosLogOut } from "react-icons/io";

export default function MobileDrawer({handleLogout}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const navigate = useNavigate();
    const handleClick = (url) => {
        navigate(url);
        onClose();
    }

    return (
      <>
        <IconButton icon={<HamburgerIcon color={'white'} fontSize="3xl"/>} ref={btnRef} onClick={onOpen} backgroundColor="transparent" alignSelf="center" justifySelf="center" m="5" />
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
                <Stack spacing={8} direction='column' mt="10" >
                    <Link onClick={() => handleClick("/shoppings")} fontSize={24} _hover={false} >Bevásárlások</Link>
                    <Link onClick={() => handleClick("/stock")} fontSize={24} _hover={false} >Készlet</Link>
                    <Link onClick={() => handleClick("/compositefoods")} fontSize={24} _hover={false} >Kész ételek</Link>
                    <Link onClick={() => handleClick("/products")} fontSize={24} _hover={false} >Termékek</Link>
                    <Link onClick={() => handleClick("/mealtypes")} fontSize={24} _hover={false} >Étkezések</Link>
                    <Link onClick={() => handleClick("/households")} fontSize={24} _hover={false} >Háztartásaim</Link>
                </Stack>
            </DrawerBody>
            <DrawerFooter>
              <HouseholdSelector/>
              <IconButton ml='2' variant='outline' icon={<IoIosLogOut/>} onClick={handleLogout}/>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    )
}
