import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
    IconButton,
    ModalHeader,
    ModalCloseButton,
    Tooltip
  } from '@chakra-ui/react'
import BaseListPage from '../../components/basePage/baseListPage';
import { FaCarrot } from "react-icons/fa";
import TemplateItemForm from './templateItemForm';
import TemplateItemListItem from './templateItemListItem';

export default function TemplateItemModal({id}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    
    return (
      <>
        <Tooltip label="Sablon ételek">
          <IconButton icon={<FaCarrot/>} onClick={onOpen} boxSize='8' size='xl' color='whitesmoke' bg='transparent'/>
        </Tooltip>
  
        <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}>
            <ModalOverlay />
            <ModalContent width="90%">
                <ModalHeader></ModalHeader>
                <ModalCloseButton/>
                <BaseListPage entityName='templateitems' title='Sablon ételek' formTitle='Sablon étel' listItem={TemplateItemListItem} form={TemplateItemForm} mt='0' foreignKeyName='mealTypeId' foreignKey={id} bg='#548235' mildReload={true} sortStrategy={(a, b) => (a.foodName > b.foodName) ? 1 : ((a.foodName < b.foodName) ? -1 : 0)}/>
            </ModalContent>
        </Modal>
      </>
    )
}
