import React from 'react';
import {
    FormControl,
    FormLabel,
    VStack,
    Input,
    Textarea
} from '@chakra-ui/react'
import DragDrop from '../../components/products/dragDrop';

export default function CompositeFoodForm({title, data, setData, setImage, isEdit}){
    const setName = (name) => {
        if (!data) {
            data = {};
        }
        if (!data.HouseholdId) {
            data.HouseholdId = localStorage.getItem('householdId');
            data.householdId = localStorage.getItem('householdId');
        }
        data.ProductName = name;
        data.name = name;
        setData(data);
    };

    const setImageWrapper = (image) => {
        if (!data) {
            data = {};
        }
        if (!data.HouseholdId) {
            data.HouseholdId = localStorage.getItem('householdId');
            data.householdId = localStorage.getItem('householdId');
        }
        if (!data.ProductName && !!data.name){
            data.ProductName = data.name;
        }
        setImage(image);
    };

    const setRecipe = (recipe) => {
        if (!data) {
            data = {};
        }
        if (!data.HouseholdId) {
            data.HouseholdId = localStorage.getItem('householdId');
            data.householdId = localStorage.getItem('householdId');
        }
        data.Recipe = recipe;
        data.recipe = recipe;
        setData(data);
    };

    return (
        <VStack w='full'>
            <FormControl>
                <FormLabel>{title} neve</FormLabel>
                <Input type="text" defaultValue={!!data ? data.name : ''} onChange={(event) => setName(event.target.value)} mb='4' autoComplete='off'/>
                
                <FormLabel>Kép az ételről</FormLabel>
                <DragDrop onFileChanged={setImageWrapper} />

                <FormLabel mt='4'>Recept</FormLabel>
                <Textarea type="text" defaultValue={!!data ? data.recipe : ''} onChange={(event) => setRecipe(event.target.value)} autoComplete='off'/>
            </FormControl>
        </VStack>
    );
};
