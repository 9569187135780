import React, { useEffect, useState, useContext } from 'react';
import {
    Center,
    Grid,
    GridItem,
    VStack,
    IconButton,
    Flex,
    Divider,
} from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md';
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import MemberSelector from './memberSelector';
import DateSelector from './dateSelector';
import MealFoodModal from './mealFoodModal';
import { BsLightning } from "react-icons/bs";
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function Calendar() {
    const [mealTypes, setMealTypes] = useState();
    const [memberId, setMemberId] = useState();
    const [date, setDate] = useState({});
    const [items, setItems] = useState([]);
    const [dayItems, setDayItems] = useState([]);
    const [meals, setMeals] = useState([]);
    const [canAdd, setCanAdd] = useState();

    const { notify } = useContext(ErrorNotifierContext);

    const addMeal = (column, mealTypeId) => {
        const day = new Date();
        day.setDate(date.monday.getDate() + column - 2)
        axios.post(apiConfig.webApi + 'meals', {
            date: day.toISOString().substring(0, 10),
            mealTypeId: mealTypeId
            })
            .then(() => {
                fetchMeals();
            })
            .catch((error) => {
                notify(error, 'Sikertelen hozzáadás');
            });
    }

    const quickDailyAdd = (dayindex) => {
        const day = new Date();
        day.setDate(date.monday.getDate() + dayindex)
        axios.post(apiConfig.webApi + 'meals/day', {
                date: day.toISOString().substring(0, 10),
                memberId: memberId
            })
            .then(() => {
                fetchMeals();
            })
            .catch((error) => {
                notify(error, 'Sikertelen hozzáadás');
            });
    }

    const quickWeeklyAdd = (mealTypeId) => {
        axios.post(apiConfig.webApi + 'meals/week', {
                from: date.monday.toISOString().substring(0, 10),
                to: date.sunday.toISOString().substring(0, 10),
                mealTypeId: mealTypeId
            })
            .then(() => {
                fetchMeals();
            })
            .catch((error) => {
                notify(error, 'Sikertelen hozzáadás');
            });
    }

    useEffect(() => {
        let localitems = [];
        let localDayItems = [];
        if (!!mealTypes && !!meals) {
            for (let i = 2; i <= 8; i++) {
                for (let j = 2; j <= mealTypes?.length + 1; j++) {
                    const meal = meals.filter(item => item.gridColumn === i && item.gridRow === j);
                    if (meal.length === 1) {
                        localitems.push(<GridItem gridColumn={i} gridRow={j} key={i.toString() + j.toString()} fontSize='8' alignContent='center' textAlign='center'>
                                            <MealFoodModal item={meal[0]} callback={fetchMeals} canDelete={canAdd}/>
                                        </GridItem>);
                    }
                    else {
                        localitems.push(<GridItem gridColumn={i} gridRow={j} key={i.toString() + j.toString()} fontSize='xl' alignContent='center' textAlign='center'>{canAdd && <IconButton icon={<MdAdd/>} onClick={() => addMeal(i, mealTypes[j-2].id)} bg='transparent' />}</GridItem>);
                    }
                    if (i === 2) {
                        if (meal.length === 1) {
                            localDayItems.push(<GridItem gridColumn={2} gridRow={j} key={i.toString() + j.toString()} fontSize='8' alignContent='center' textAlign='center'>
                                                <MealFoodModal item={meal[0]} callback={fetchMeals} canDelete={canAdd}/>
                                            </GridItem>);
                        }
                        else {
                            localDayItems.push(<GridItem gridColumn={2} gridRow={j} key={i.toString() + j.toString()} fontSize='xl' alignContent='center' textAlign='center'>{canAdd && <IconButton icon={<MdAdd/>} onClick={() => addMeal(i, mealTypes[j-2].id)} bg='transparent' />}</GridItem>);
                        }
                    }
                }
            }
            setItems(localitems);
            setDayItems(localDayItems);
        }
    //eslint-disable-next-line
    }, [mealTypes, meals]);

    const fetchMeals = () => {
        if (!!date.monday && !!date.sunday && !!memberId) {
            const from = date.monday.toISOString().substring(0, 10);
            const to = date.sunday.toISOString().substring(0, 10);
            axios.get(apiConfig.webApi + `meals?memberId=${memberId}&from=${from}&to=${to}&isDay=${from===to}`)
                .then((response) => {
                    setMeals(response.data.list);
                    setCanAdd(response.data.canAdd);
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezése az étkezéseknek');
                });
        }
    };

    useEffect(() => {
        if (!!memberId) {
            axios.get(apiConfig.webApi + 'mealtypes?memberId=' + memberId)
                .then((response) => {
                    let i = 2;
                    response.data.list.forEach(item => {
                        item.gridColumn = 1;
                        item.gridRow = i;
                        i++;
                    });
                    setMealTypes(response.data.list);
                })
                .catch((error) => {
                    notify(error, 'Sikertelen lekérdezése az étkezéstípusoknak');
                });
        }

        fetchMeals();
    //eslint-disable-next-line
    }, [memberId]);

    useEffect(() => {
        fetchMeals();
    //eslint-disable-next-line
    }, [date]);

    return(
        <Center mt='20' w='100%'>
            <VStack className='mobile-calendar' w="400px">
                <Flex justifyContent='space-between' w='100%' mt='10'>
                    <MemberSelector onChange={setMemberId} />
                    {!!memberId && <DateSelector onChange={setDate} day={true}/>}
                </Flex>
                <Divider orientation='horizontal' my='4' />
                {!!memberId && <Grid templateRows={'repeat(' + (mealTypes?.length + 1) + ', 50px)'} templateColumns='repeat(2, 100px)' gap={4}>
                    <GridItem gridColumn={2} gridRow={0} fontSize='sm' alignContent='center' textAlign='center'>{date.monday?.toLocaleDateString()}{canAdd && <IconButton icon={<BsLightning/>} bg='transparent' mb='1' size='sm' onClick={() => quickDailyAdd(0)}/>}</GridItem>
                    {!!mealTypes && mealTypes.map(item => <GridItem gridColumn={item.gridColumn} gridRow={item.gridRow} key={item.id} fontSize='sm' alignContent='center' textAlign='center'>{ item.name }</GridItem>)}
                    {dayItems}
                </Grid>}
            </VStack>
            <VStack className='desktop-calendar'>
                <Flex justifyContent='space-between' w='100%' mt='10'>
                    <MemberSelector onChange={setMemberId} />
                    {!!memberId && <DateSelector onChange={setDate}/>}
                </Flex>
                <Divider orientation='horizontal' my='4' />
                {!!memberId && <Grid templateRows={'repeat(' + (mealTypes?.length + 1) + ', 50px)'} templateColumns='repeat(8, 100px)' gap={4}>
                    <GridItem gridColumn={2} gridRow={0} fontSize='sm' alignContent='center' textAlign='center'>Hétfő{canAdd && <IconButton icon={<BsLightning/>} bg='transparent' mb='1' size='sm' onClick={() => quickDailyAdd(0)}/>}</GridItem>
                    <GridItem gridColumn={3} gridRow={0} fontSize='sm' alignContent='center' textAlign='center'>Kedd{canAdd && <IconButton icon={<BsLightning/>} bg='transparent' mb='1' size='sm' onClick={() => quickDailyAdd(1)}/>}</GridItem>
                    <GridItem gridColumn={4} gridRow={0} fontSize='sm' alignContent='center' textAlign='center'>Szerda{canAdd && <IconButton icon={<BsLightning/>} bg='transparent' mb='1' size='sm' onClick={() => quickDailyAdd(2)}/>}</GridItem>
                    <GridItem gridColumn={5} gridRow={0} fontSize='sm' alignContent='center' textAlign='center'>Csütörtök{canAdd && <IconButton icon={<BsLightning/>} bg='transparent' mb='1' size='sm' onClick={() => quickDailyAdd(3)}/>}</GridItem>
                    <GridItem gridColumn={6} gridRow={0} fontSize='sm' alignContent='center' textAlign='center'>Péntek{canAdd && <IconButton icon={<BsLightning/>} bg='transparent' mb='1' size='sm' onClick={() => quickDailyAdd(4)}/>}</GridItem>
                    <GridItem gridColumn={7} gridRow={0} fontSize='sm' alignContent='center' textAlign='center'>Szombat{canAdd && <IconButton icon={<BsLightning/>} bg='transparent' mb='1' size='sm' onClick={() => quickDailyAdd(5)}/>}</GridItem>
                    <GridItem gridColumn={8} gridRow={0} fontSize='sm' alignContent='center' textAlign='center'>Vasárnap{canAdd && <IconButton icon={<BsLightning/>} bg='transparent' mb='1' size='sm' onClick={() => quickDailyAdd(6)}/>}</GridItem>
                    {!!mealTypes && mealTypes.map(item => <GridItem gridColumn={item.gridColumn} gridRow={item.gridRow} key={item.id} fontSize='sm' alignContent='center' textAlign='center'>{ item.name }{canAdd && <IconButton icon={<BsLightning/>} bg='transparent' mb='1' size='sm' onClick={() => quickWeeklyAdd(item.id)}/>}</GridItem>)}
                    {items}
                </Grid>}
            </VStack>
        </Center>
    );
}
