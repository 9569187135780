import React from 'react';
import BaseListPage from '../../components/basePage/baseListPage';
import ProductListItem from './productListItem';
import ProductForm from './productForm';

export default function ProductsPage({ householdId }) {
    return (<>
        <BaseListPage
            entityName='products'
            title='Termékek'
            formTitle='Termék'
            listItem={ProductListItem}
            form={ProductForm}
            foreignKeyName='householdId'
            foreignKey={householdId}
            bg='#548235'
            sortStrategy={(a, b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0)} />
    </>)
}
