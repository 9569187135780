import React, { useState, useEffect } from 'react';
import {
    IconButton,
    HStack,
    Text
} from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

export default function DateSelector({onChange, day}){
    const [data, setData] = useState({});

    const calcDays = (date) => {
        if (!day) {
            const dateDiff = (date.getDay() + 6) % 7;
            const monday = new Date(date);
            monday.setDate(date.getDate() - dateDiff);
            const sunday = new Date(date);
            sunday.setDate(monday.getDate() + 6);
            data.monday = monday;
            data.sunday = sunday;
        }
        else {
            const monday = new Date(date);
            const sunday = new Date(date);
            data.monday = monday;
            data.sunday = sunday;
        }
        onChange(data);
    }

    useEffect(() => {
        const date = new Date();
        calcDays(date);
    //eslint-disable-next-line
    }, []);

    const increment = () => {
        if (!day) {
            const newMonday = new Date(data.monday);
            const newSunday = new Date(data.sunday);
            newMonday.setDate(newMonday.getDate() + 7);
            newSunday.setDate(newSunday.getDate() + 7);
            const newData = { monday: newMonday, sunday: newSunday };
            setData(newData);
            onChange(newData);
        }
        else {
            const newMonday = new Date(data.monday);
            const newSunday = new Date(data.sunday);
            newMonday.setDate(newMonday.getDate() + 1);
            newSunday.setDate(newSunday.getDate() + 1);
            const newData = { monday: newMonday, sunday: newSunday };
            setData(newData);
            onChange(newData);
        }
    }
    
    const decrement = () => {
        if (!day) {
            const newMonday = new Date(data.monday);
            const newSunday = new Date(data.sunday);
            newMonday.setDate(newMonday.getDate() - 7);
            newSunday.setDate(newSunday.getDate() - 7);
            const newData = { monday: newMonday, sunday: newSunday };
            setData(newData);
            onChange(newData);
        }
        else{
            const newMonday = new Date(data.monday);
            const newSunday = new Date(data.sunday);
            newMonday.setDate(newMonday.getDate() - 1);
            newSunday.setDate(newSunday.getDate() - 1);
            const newData = { monday: newMonday, sunday: newSunday };
            setData(newData);
            onChange(newData);
        }
    }

    return (
        <HStack>
            <IconButton icon={<ChevronLeftIcon/>} onClick={decrement} bg='transparent'/>
            {!day && <Text>{data?.monday?.toLocaleDateString()} - {data?.sunday?.toLocaleDateString()}</Text>}
            <IconButton icon={<ChevronRightIcon/>} onClick={increment} bg='transparent' />
        </HStack>
    );
};
