import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
    IconButton,
    ModalHeader,
    ModalCloseButton,
    Tooltip
  } from '@chakra-ui/react'
import BaseListPage from '../../components/basePage/baseListPage';
import { FaCarrot } from "react-icons/fa";
import CompositeItemForm from './compositeItemForm';
import CompositeItemListItem from './compositeItemListItem';

export default function CompositeItemModal({id}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    
    return (
      <>
        <Tooltip label="Hozzávalók">
          <IconButton icon={<FaCarrot/>} onClick={onOpen} boxSize='8' size='xl' color='whitesmoke' bg='transparent'/>
        </Tooltip>
  
        <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}>
            <ModalOverlay />
            <ModalContent width="90%">
                <ModalHeader></ModalHeader>
                <ModalCloseButton/>
                <BaseListPage entityName='compositeitems' title='Hozzávalók' formTitle='Hozzávaló' listItem={CompositeItemListItem} form={CompositeItemForm} mt='0' foreignKeyName='compositeFoodId' foreignKey={id} bg='#548235' mildReload={true} sortStrategy={(a, b) => (a.productName > b.productName) ? 1 : ((a.productName < b.productName) ? -1 : 0)}/>
            </ModalContent>
        </Modal>
      </>
    )
}
