import React, { useEffect, useState, useContext } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    IconButton,
    useDisclosure,
    useToast,
  } from '@chakra-ui/react'
import { MdEdit, MdAdd } from "react-icons/md"
import axios from 'axios';
import apiConfig from '../../config/apiconfig';
import { ErrorNotifierContext } from '../../providers/errorNotifier';

export default function BaseForm({isEdit, entity, entityName, title, callback, form, foreignKey, bg, small}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [data, setData] = useState(isEdit ? entity : undefined);
    const [image, setImage] = useState();
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const toast = useToast();
    const { notify } = useContext(ErrorNotifierContext);

    let button, header;
    if (isEdit) {
        button = <IconButton icon={<MdEdit/>} onClick={onOpen} boxSize='8' size='xl' color='whitesmoke' bg='transparent'/>;
        header = title + " szerkesztése";
    } else {
        button = <IconButton icon={<MdAdd/>} onClick={onOpen} boxSize='10' color='whitesmoke' size='6xl' bg={bg}/>;
        header = title + " hozzáadása";
    }

    const submitFunction = (event) => {
      event.preventDefault();
      if(isEdit){
        const formData = new FormData();
        var url = apiConfig.webApi + entityName + '/' + data.id;
        var headers = {};
        for (const property in data) {
          formData.append(property, data[property]);
        }
        if (!!image) {
          formData.append('ImageFile', image);
          url = apiConfig.webApi + entityName + '/withimage/' + data.id;
          headers = { 'Content-Type': 'multipart/form-data' };
        }
        
        axios.put(url, !!image ? formData : data, headers)
          .then((newData) => {
              onClose();
              callback(data.id, newData.data);
              setData('');
              setImage('');
              toast({ title: 'Sikeres mentés', status: 'success', duration: 2000, isClosable: true});
          })
          .catch((error) => {
              notify(error, 'Sikertelen mentés');
          });
      }
      else {
        const formData = new FormData();
        var posturl = apiConfig.webApi + entityName;
        var postheaders = {};
        for (const property in data) {
          formData.append(property, data[property]);
        }
        if (!!image) {
          formData.append('ImageFile', image);
          posturl = posturl + '/withimage';
          postheaders = { 'Content-Type': 'multipart/form-data' };
        }

        axios.post(posturl, !!image ? formData : data, postheaders)
        .then((response) => {
            data.id = response.data.id;
            setData('');
            setImage('');
            onClose();
            callback(data.id, response.data);
            toast({ title: 'Sikeres mentés', status: 'success', duration: 2000, isClosable: true});
        })
        .catch((error) => {
            notify(error, 'Sikertelen mentés');
        });
      }
    }

    useEffect(() => {
      if(!entity){
        // ne maradjanak benne a formok kozott mas entitsokbol adatok
        setData('');
        setImage('');
      }
      if (!data && !!entity) {
        // a sajatja viszont maradjon
        setData(entity);
      }
    //eslint-disable-next-line
    }, [entity, entityName]);
  
    return (
      <>
        {button}
  
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent width="90%">
            <form onSubmit={submitFunction}>
              <ModalHeader>{header}</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                {form({title, data, setData, foreignKey, setImage, isEdit})}
              </ModalBody>
    
              <ModalFooter>
                <Button onClick={onClose}>Mégse</Button>
                <Button type="submit" bg='#548235' color='whitesmoke' ml={3}>Mentés</Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    )
}
