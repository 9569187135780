import React, { createContext } from 'react';
import {
    useToast
} from '@chakra-ui/react';
import axios from 'axios';
import apiConfig from '../config/apiconfig';

export const ErrorNotifierContext = createContext();

export const ErrorNotifier = ({ children }) => {
    const toast = useToast();

    const notify = (error, generalMessage) => {
        if (error.code === "ERR_NETWORK") {
            toast({ title: 'Sikertelen kapcsolódás a szerverhez', status: 'error', duration: 2000, isClosable: true });
        }
        else if (error?.response?.status === 403) {
            toast({ title: 'Ehhez a művelethez nincsen jogosultságod!', status: 'error', duration: 2000, isClosable: true });
        }
        else if (error?.response?.status === 401) {
            axios.post(apiConfig.baseUrl + 'refresh', { refreshToken: localStorage.getItem("refreshToken") })
                .then((response) => {
                    localStorage.setItem("accessToken", response.data.accessToken);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
                });
            toast({ title: 'Lejárt a munkameneted, de mi már meghosszabbítottuk neked. Próbáld újra!', status: 'error', duration: 2000, isClosable: true });
        }
        else if (error.response.status === 404) {
            toast({ title: 'Nem létezik, amit keresel', status: 'error', duration: 2000, isClosable: true })
        }
        else if (error.response.status === 400 || error.response.status === 415) {
            toast({ title: 'Kérlek, minden kötelező mezőt helyesen tölts ki!', status: 'error', duration: 2000, isClosable: true });
        }
        else if (error?.response?.status === 500 && error?.response?.data?.detail === 'Duplicate') {
            toast({ title: 'Ilyen sor már létezik!', status: 'error', duration: 2000, isClosable: true });
        } else {
            toast({ title: generalMessage, status: 'error', duration: 2000, isClosable: true });
        }
    };

    const value = {
        notify
    };

    return (
        <ErrorNotifierContext.Provider value={value}>
            {children}
        </ErrorNotifierContext.Provider>
    );
};
